<div class="footer__top">
  <div class="footer__top__inner">
    <ul class="footer__top__list">
      <li class="footer__top__item">
        <a class="footer__top__link" routerLink="/{{ language }}/privacy-policy">{{
          "footer.privacy_policy" | translate
        }}</a>
      </li>
      <li class="footer__top__item">
        <a class="footer__top__link" routerLink="/{{ language }}/cookie-policy">{{
          "footer.cookie_policy" | translate
        }}</a>
      </li>
    </ul>
  </div>
</div>
<div class="footer__bottom">
  <div class="footer__bottom__inner">
    <div class="footer__bottom__links">
      <span [innerHtml]="'footer.copyright' | translate"></span
      >
      <span [innerHtml]="'footer.trademark' | translate"></span
      >
      <ul class="footer__bottom__list">
        <li class="footer__bottom__item">
          <a
            href="https://api.milliken.com/legaldocs/v1/docs/termsofuse/public"
            target="_blank"
            class="footer__bottom__link"
            [innerHtml]="'footer.termsOfUse' | translate"></a
          >
        </li>
        <li class="footer__bottom__item">
          <a
            href="https://api.milliken.com/legaldocs/v1/docs/termsandconditions/public"
            target="_blank"
            class="footer__bottom__link"
            [innerHtml]="'footer.termsAndConditionsSale' | translate"></a
          >
        </li>
        <li class="footer__bottom__item">
          <a
            href="https://api.milliken.com/legaldocs/v1/docs/termsandconditionspurchase/public"
            target="_blank"
            class="footer__bottom__link"
            [innerHtml]="'footer.termsAndConditionsPurchase' | translate"></a
          >
        </li>
        <li class="footer__bottom__item">
          <a
            href="https://api.milliken.com/legaldocs/v1/docs/privacy/public"
            target="_blank"
            class="footer__bottom__link"
            [innerHtml]="'footer.privacyPolicy' | translate"></a
          >
        </li>
        <li class="footer__bottom__item">
          <a
            href="https://api.milliken.com/legaldocs/v1/docs/trademark/public"
            target="_blank"
            class="footer__bottom__link"
            [innerHtml]="'footer.trademarks' | translate"></a
          >
        </li>
      </ul>
    </div>
    <a class="footer__bottom__logo">
      <img
        src="assets/images/Milliken-logo-white.svg"
        alt="Milliken Logo"
        routerLink="/{{ language }}"
      />
    </a>
  </div>
</div>
