import { Component, OnInit } from "@angular/core";

@Component({
  selector: "millad-cookie-policy",
  templateUrl: "./cookie-policy.component.html",
  styleUrls: ["../policy.scss"],
})
export class CookiePolicyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
