import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'millad-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(private _renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    let script2 = this._renderer2.createElement('script');
        script2.text = `
          hbspt.forms.create({
            region: "na1",
            portalId: "2048083",
            formId: "1b645252-2be8-4f1f-a626-4b35f2688818",
            sfdcCampaignId: "701Ql000002PCHlIAO",
            target: "#hubspotForm" 
          });
        `;

    this._renderer2.appendChild(this._document.body, script2);
  }

}
