import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LanguageGuard } from "./localization/language-guard";
import { CookiePolicyComponent } from "./policy/cookie-policy/cookie-policy.component";
import { PrivacyPolicyComponent } from "./policy/privacy-policy/privacy-policy.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";

const routes: Routes = [
  {
    path: ":lang",
    resolve: [LanguageGuard],
    children: [
      {
        path: "savings-calculator",
        loadChildren: () =>
          import("././savings-calculator/savings-calculator.module").then(
            (sc) => sc.SavingsCalculatorModule
          ),
      },
      {
        path: "performance-simulator",
        loadChildren: () =>
          import("././performance-simulator/performance-simulator.module").then(
            (ps) => ps.PerformanceSimulatorModule
          ),
      },
      {
        path: "privacy-policy",
        component: PrivacyPolicyComponent,
      },
      {
        path: "cookie-policy",
        component: CookiePolicyComponent,
      },
      {
        path: "contact-us",
        component: ContactUsComponent,
      },
      {
        path: "",
        loadChildren: () =>
          import("././home/home.module").then((h) => h.HomeModule),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "en",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
