<div class="policy">
  <h2>{{ "cookie_policy.header" | translate }}</h2>
  <p>{{ "cookie_policy.description" | translate }}</p>
  <h4>
    {{ "cookie_policy.cookies" | translate }}
  </h4>
  <p>
    {{ "cookie_policy.cookies_description_1" | translate }}
  </p>
  <p>
    {{ "cookie_policy.cookies_description_2" | translate }}
  </p>
  <p>
    {{ "cookie_policy.cookies_description_3" | translate }}
  </p>
  <h4>{{ "cookie_policy.cookie_control" | translate }}</h4>
  <p>
    {{ "cookie_policy.cookie_control_description" | translate }}
  </p>
  <div class="links">
    <p>
      Google Chrome
      <a href="https://support.google.com/chrome/answer/95647?hl=en"
        >https://support.google.com/chrome/answer/95647?hl=en</a
      >
    </p>
    <p>
      Edge
      <a
        href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
        >https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d</a
      >
    </p>
    <p>
      Mozilla Firefox
      <a
        href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox"
        >https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox</a
      >
    </p>
    <p>
      Safari
      <a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
        >https://support.apple.com/en-gb/guide/safari/sfri11471/mac</a
      >
    </p>
  </div>
  <h4>{{ "cookie_policy.cookie_list" | translate }}</h4>
  <table>
    <tr>
      <td>{{ "cookie_policy.third_party_cookie" | translate }}</td>
      <td>{{ "cookie_policy.from" | translate }}</td>
      <td>{{ "cookie_policy.conversation_period" | translate }}</td>
    </tr>
    <tr>
      <td>_Secure-3PSIDCC</td>
      <td>Google</td>
      <td>1 year</td>
    </tr>
  </table>
</div>
