import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "millad-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  isMobileNavVisible: boolean = false;
  language: string = "";

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.language = this.translateService.currentLang;
  }

  showMobileNavigation() {
    this.isMobileNavVisible = !this.isMobileNavVisible;
  }
  translateLanguageTo(lang: string) {
    this.translateService.use(lang);
  }
}
