import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageWithTextComponent } from "./image-with-text/image-with-text.component";
import { HeroComponent } from "./hero/hero.component";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { SliderInputComponent } from "./input/slider-input/slider-input.component";
import { UnitInputComponent } from "./input/unit-input/unit-input.component";
import { SelectInputComponent } from "./input/select-input/select-input.component";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { CheckboxComponent } from './input/checkbox/checkbox.component';
import { LimitDecimalsDirective } from './directives/limit-decimals.directive';

@NgModule({
  declarations: [
    HeroComponent,
    ImageWithTextComponent,
    SliderInputComponent,
    UnitInputComponent,
    SelectInputComponent,
    CheckboxComponent,
    LimitDecimalsDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    NgSelectModule,
    ReactiveFormsModule,
  ],
  exports: [
    HeroComponent,
    ImageWithTextComponent,
    TranslateModule,
    SliderInputComponent,
    UnitInputComponent,
    SelectInputComponent,
    ReactiveFormsModule,
    CheckboxComponent,
  ],
})
export class SharedModule {}
