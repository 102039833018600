<div class="policy">
  <h2>{{ "policy.privacy_notice" | translate }}</h2>
  <h4>{{ "policy.privacy_background_title" | translate }}</h4>
  <p [innerHtml]="'policy.privacy_background_description1' | translate"></p>
  <p>
    {{ "policy.privacy_background_description2" | translate }}
  </p>
  <h4>{{ "policy.privacy_notice" | translate }}</h4>
  <p>
    {{ "policy.privacy_notice_p1" | translate }}
  </p>
  <p>
    <span class="bolded"
      >{{ "policy.privacy_notice_p2_bold" | translate }}
    </span>
    {{ "policy.privacy_notice_p2" | translate }}
    <span class="bolded"
      >{{ "policy.privacy_notice_p2_bold2" | translate }}
    </span>
  </p>
  <p>
    <span class="bolded"
      >{{ "policy.privacy_notice_p3_bold" | translate }}
    </span>
    {{ "policy.privacy_notice_p3" | translate }}
  </p>
  <p>
    <span class="bolded"
      >{{ "policy.privacy_notice_p4_bold" | translate }}
    </span>
    {{ "policy.privacy_notice_p4" | translate }}
  </p>
  <p>
    <span class="bolded"
      >{{ "policy.privacy_notice_p5_bold" | translate }}
    </span>
    {{ "policy.privacy_notice_p5" | translate }}
  </p>
  <p>
    <span class="bolded"
      >{{ "policy.privacy_notice_p6_bold" | translate }}
    </span>
    {{ "policy.privacy_notice_p6" | translate }}
  </p>
  <p>
    <span class="bolded"
      >{{ "policy.privacy_notice_p7_bold" | translate }}
    </span>
    {{ "policy.privacy_notice_p7" | translate }}
  </p>
  <p>
    <span class="bolded"
      >{{ "policy.privacy_notice_p8_bold" | translate }}
    </span>
    {{ "policy.privacy_notice_p8" | translate }}
  </p>
  <p>
    <span class="bolded"
      >{{ "policy.privacy_notice_p9_bold" | translate }}
    </span>
    {{ "policy.privacy_notice_p9" | translate }}
  </p>
  <p>
    {{ "policy.privacy_notice_p10" | translate }}
  </p>
  <p>
    {{ "policy.privacy_notice_p11" | translate }}
    <a href="mailto:privacy@milliken.com">privacy@milliken.com</a>
  </p>
  <p>
    {{ "policy.privacy_notice_p12" | translate }}
    <a href="http://www.milliken.com/privacy"
      >http://www.milliken.com/privacy</a
    >
  </p>
</div>
